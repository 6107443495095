import { graphql } from "gatsby";
import React from "react";
import ContactForm from "../components/contactForm/form";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import Integrations from "../components/integrations/integrations";
import Seo from "../components/seo";

// markup
const WhyOrdersCoPage = ({ location, data }) => {
  console.log("xxx", data);
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Seo
        title={"Why Orders.Co"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <h2 className="text-center">Why Orders.Co (Coming soon)</h2>
        <Integrations scrollToForm={scrollToForm}></Integrations>
        <div ref={formRef}>
          <ContactForm></ContactForm>
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "why-orders-co" }) {
      title
      id
      slug
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default WhyOrdersCoPage;
